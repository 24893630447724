import { Attributes, List, Misc, NeoModel, NumberUtils, Rules, Validation } from '@singularsystems/neo-core';
import ParticipantAwardLookupBase from '../../../../../Common/Models/Base/ParticipantAwardLookupBase';
import { ParticipantAwardSelectionOption } from '../../../../../Common/Models/Enums/ParticipantAwardSelectionOption';
import { AwardPriceType } from '../../../../../Common/Models/Enums/AwardPriceType';
import { OptionSelectionType } from '../../../../../Common/Models/Enums/OptionSelectionType';
import { QuestionnaireSummary } from '../QuestionnaireSummary';
import AwardPrepConfig from '../../../../../Common/Models/Config/AwardPrepConfig';
import MyAwardDocumentLookup from './MyAwardDocumentLookup';

@NeoModel
export default class MyAwardLookup extends ParticipantAwardLookupBase {

    public awardOptionId: number = 0;

    public priceType: AwardPriceType | null = null;

    public selectionType: OptionSelectionType | null = null;

    @Attributes.Float()
    public minPercentageLimit: number = 0;

    @Attributes.Float()
    public maxPercentageLimit: number = 0;

    @Attributes.Float()
    public requiredAcceptanceIncrement: number = 0;

    public defaultOption: ParticipantAwardSelectionOption = ParticipantAwardSelectionOption.Accept;

    public selectedOption: ParticipantAwardSelectionOption | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    @Attributes.OnChanged<MyAwardLookup>(c => c.selectedPercentChanged)
    public selectedPercent: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public totalSelectedPercent: number = 0;

    @Attributes.Date()
    public selectedOn: Date | null = null;

    @Attributes.Date()
    public acceptanceActionBy: Date | null = null;

    public accepted: boolean = false;

    @Attributes.Date()
    public acceptedSelectionOn: Date | null = null;

    @Attributes.Date()
    public awardDocumentSetLastGeneratedOn: Date | null = null;

    public documents = new List(MyAwardDocumentLookup);

    @Attributes.ChildObject(QuestionnaireSummary)
    public questionnaire: QuestionnaireSummary | null = null;

    @Attributes.ChildObject(AwardPrepConfig)
    public awardConfig: AwardPrepConfig | null = new AwardPrepConfig();

    // Client only properties / methods

    @Attributes.OnChanged<MyAwardLookup>(c => c.clearSelectedParticipantEntity)
    public useParticipantEntity: boolean = false;

    @Attributes.Nullable()
    public participantEntityId: number | null = null;

    public selectedParticipantEntityName: string = "";

    public get canMakeSelection() {
        // can select if
        //      - Accept and Decline if more than 1 award
        //      - Is Partial Accept / Decline
        return (this.selectionType === OptionSelectionType.AcceptOrDecline && (((this.selectedPercent ?? this.optionPercentage) < this.totalSelectedPercent) || this.totalSelectedPercent === 0)) || 
            this.selectionType === OptionSelectionType.PartialAcceptOrDecline;
    }

    public get hasMadeSelection() {
        return this.selectedOn !== null;
    }

    public get hasQuestionnaire() {
        return this.questionnaire !== null && this.questionnaire.questionsCount > 0;
    }

    public get questionnaireCompleted() {
        return this.questionnaire === null || this.questionnaire.questionnaireId === 0 || this.questionnaire.completedOn !== null;
    }

    public get documentsCompleted() {
        return this.documents.length === 0 || this.documents.every(c => c.isCompleted);
    }

    public get hasDocumentsThatRequireCompletion() {
        return this.documents.some(c => c.requiresCompletion);
    }

    public get selectionCompleted() {
        return !this.canMakeSelection || this.selectedOn != null;
    }

    public get readyForAcceptance() {
        return this.questionnaireCompleted && this.documentsCompleted && this.selectionCompleted;
    }

    public get hasAdditionalAcceptSteps() {
        return this.canMakeSelection ||
            this.hasQuestionnaire ||
            !this.documentsCompleted;
    }

    public get readyForDecline() {
        const questionnaireRequiredForDecliningParticipants = this.awardConfig?.questionnaireRequiredForDecliningParticipants ?? false;
        return (!questionnaireRequiredForDecliningParticipants ||
            (questionnaireRequiredForDecliningParticipants && (this.questionnaire === null || this.questionnaire.questionnaireId === 0 || this.questionnaire.completedOn))) &&
            this.documentsCompleted;
    }

    public selectedPercentChanged() {
        if (this.selectedPercent) {
            this.optionPercentage = this.selectedPercent;
        } else {
            this.optionPercentage = 0;
        }
    }

    private clearSelectedParticipantEntity() {
        if (!this.useParticipantEntity) {
            this.participantEntityId = null;
            this.selectedParticipantEntityName = "";
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(
            c => (c.selectedPercent ?? 0) < c.minPercentageLimit && (c.selectedOption ?? c.defaultOption) === ParticipantAwardSelectionOption.Accept,
            c => `You must select >= ${NumberUtils.format(c.minPercentageLimit, Misc.NumberFormat.PercentNoDecimals)}`);

        rules.failWhen(
            c => (c.selectedPercent ?? 0) > c.maxPercentageLimit && (c.selectedOption ?? c.defaultOption) === ParticipantAwardSelectionOption.Accept,
            c => `You must select <= ${NumberUtils.format(c.maxPercentageLimit, Misc.NumberFormat.PercentNoDecimals)}`);
    }

    public toString(): string {
        if (this.isNew) {
            return "New my award lookup";
        } else {
            return "My Award Lookup";
        }
    }
}