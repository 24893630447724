import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import PortfolioSettingsLookup from './PortfolioSettingsLookup';
import PortfolioIndicators from './PortfolioIndicators';
import PortfolioBalanceLookup from './PortfolioBalanceLookup';
import CurrencyLookup from '../../../Common/Models/CurrencyLookup';
import ExchangeRateLookup from '../../../Common/Models/ExchangeRateLookup';
import { Awards } from '../../../../App';
import InstrumentLookup from '../../../Common/Models/InstrumentLookup';
import TradeRequestLookup from '../Trading/Lookups/TradeRequestLookup';
import SecondmentLookup from '../../../../Participants/ParticipantsApp/Models/Lookups/SecondmentLookup';
import { AppService, Types } from '../../TransactionsTypes';

@NeoModel
export default class AppDataLookup extends LookupBase {

    @Attributes.ChildObject(PortfolioSettingsLookup)
    public settings = new PortfolioSettingsLookup();

    @Attributes.ChildObject(PortfolioIndicators)
    public portfolioIndicators = new PortfolioIndicators();

    public trancheBalances = new List(PortfolioBalanceLookup);

    /**
     * List of currencies from the transactions service.
     */
    private currencies = new List(CurrencyLookup);

    public mainCurrencies: CurrencyLookup[] = [];

    public exchangeRates = new List(ExchangeRateLookup);

    @Attributes.Observable()
    public pendingTradeRequests = new List(TradeRequestLookup);

    public participantSecondments = new List(SecondmentLookup);

    public get paymentCountryList() {
        const countryList = this.participantSecondments
            .groupBy(c => c.countryId)
            .map(g => ({ countryId: g.item.countryId, countryName: g.item.countryName }));

        if (!countryList.find(c => c.countryId === this.settings.residentCountryId)) {
            countryList.push({
                countryId: this.settings.residentCountryId,
                countryName: this.settings.residentCountryName
            });
        }
        return countryList;
    }
    // Client only properties / methods

    public pendingTradeRequestsLoaded = false;

    public instruments: InstrumentLookup[] = [];

    @Attributes.Observable()
    private _instrumentEditMode = false;

    public get instrumentEditMode() {
        return this._instrumentEditMode;
    }
    public set instrumentEditMode(value: boolean) {
        this._instrumentEditMode = value;
        this.instruments.forEach(c => c.customPrice = value ? c.price : null);
    }

    public incentiveSchemes: Awards.IncentiveSchemeLookup[] = [];

    public ownedInstruments: InstrumentLookup[] = [];

    public get hasUnvested() {
        return this.trancheBalances.filter(c => !c.isVested).length > 0;
    }

    public get hasVested() {
        return this.trancheBalances.filter(c => c.isVested && c.availableBalance > 0).length > 0;
    }

    public setup(instruments: InstrumentLookup[], incentiveSchemes: Awards.IncentiveSchemeLookup[]) {

        this.mainCurrencies = this.currencies.filter(c => c.isMain);

        if (this.settings.taxPercentage === null) {
            this.settings.taxPercentage = 0;
            this.settings.missingTaxRate = true;
        }

        this.incentiveSchemes = incentiveSchemes;
        this.instruments = instruments;

        const balanceByInstrument = this.trancheBalances.groupBy(c => c.instrumentId);

        for (let byInstrument of balanceByInstrument) {
            const instrumentLookup = instruments.find(c => c.instrumentCode === byInstrument.item.instrumentCode)!;

            for (let tranche of byInstrument.details) {
                tranche.instrument = instrumentLookup;
                tranche.settings = this.settings;
                tranche.incentiveScheme = incentiveSchemes.find(c => c.incentiveSchemeId === tranche.incentiveSchemeId)!;

                // Check to see if the list is not null and contains elements
                if (this.settings.participantIncentiveSchemeTypeTaxRates != null && this.settings.participantIncentiveSchemeTypeTaxRates.length > 0) {
                    let customIncentiveSchemeTypeTaxRate = this.settings.participantIncentiveSchemeTypeTaxRates.find(c => c.incentiveSchemeTypeId === tranche.incentiveScheme.incentiveSchemeTypeId)?.taxRate;
                    tranche.taxRate = customIncentiveSchemeTypeTaxRate ?? this.settings.taxPercentageOrDefault;
                } else {
                    tranche.taxRate = this.settings.taxPercentageOrDefault;
                }

            }

            const price = byInstrument.details.find(c => c.fixedPrice === null);
            if (price) {
                instrumentLookup.price = price.instrumentPrice;
            }
        }

        for (let tranche of this.trancheBalances.filter(c => !!c.trackingInstrumentCode)) {
            const trackingInstrumentLookup = instruments.find(c => c.instrumentCode === tranche.trackingInstrumentCode);
            if (trackingInstrumentLookup) {
                tranche.trackingInstrument = trackingInstrumentLookup;
                trackingInstrumentLookup.price = tranche.trackingInstrumentPrice;
            }
        }

        for (let tranche of this.trancheBalances.filter(c => !!c.settlementInstrumentCode)) {
            const settlementInstrumentLookup = instruments.find(c => c.instrumentCode === tranche.settlementInstrumentCode);
            if (settlementInstrumentLookup) {
                tranche.settlementInstrument = settlementInstrumentLookup;
                settlementInstrumentLookup.price = tranche.settlementInstrumentPrice;
            }
        }

        const balanceByTrackingInstrument = this.trancheBalances.filter(c => !!c.trackingInstrumentCode).groupBy(c => c.trackingInstrumentCode);

        this.ownedInstruments = balanceByInstrument.map(c => c.item.instrument)
            .concat(balanceByTrackingInstrument.map(c => c.item.trackingInstrument!))
            .groupBy(c => c.instrumentCode, (k, i, d) => i)
            .sortBy(c => c.instrumentCode);

        this.setupLinkedTranches();
    }

    private setupLinkedTranches() {
        const byAwardLink = this.trancheBalances.filter(c => c.awardLinkId !== null).groupBy(c => `${c.awardLinkId!}.${c.vestingDate.getTime()}`);
        for (let awardLink of byAwardLink) {
            for (let tranche of awardLink.details) {
                tranche.linkedTranches = [];
            }
            awardLink.item.linkedTranches = awardLink.details;
        }
    }

    public setupPendingTradeRequests() {
        this.populateTradeRequests(this.pendingTradeRequests);
        this.pendingTradeRequestsLoaded = true;
    }

    public populateTradeRequests(tradeRequests: TradeRequestLookup[]) {
        tradeRequests.forEach(tr => {
            tr.settlementRequiresTaxDirective = this.settings.settlementRequiresTaxDirective;
            tr.incentiveSchemeName = this.incentiveSchemes.find(c => c.incentiveSchemeId === tr.incentiveSchemeId)?.incentiveSchemeName ?? "";
            tr.currencySymbol = this.instruments.find(c => c.instrumentCode === tr.instrumentCode)?.currencySymbol ?? "";

            for (let tranche of tr.tranches) {
                tranche.trancheBalance = this.trancheBalances.find(c => tranche.trancheId === c.trancheId) ?? null;
            }
            tr.hasTrancheData = tr.tranches.length > 0 && tr.tranches.every(c => c.trancheBalance !== null);
        });
    }

    public setInstrumentRates(currency: CurrencyLookup | null) {
        let hasMissingExchangeRates = false;

        // Instruments
        for (let instrument of this.ownedInstruments) {
            if (currency) {
                const instrumentCurrencyId = this.currencies.find(c => c.currencyCode === instrument.currencyCode)?.currencyId ?? 0;
                instrument.conversionCurrency = currency;

                if (instrumentCurrencyId === currency.currencyId) {
                    instrument.rate = 1;
                } else {
                    const exchangeRate = this.exchangeRates.find(c => c.fromCurrencyId === instrumentCurrencyId);
                    if (exchangeRate) {
                        instrument.rate = exchangeRate.rate;
                    } else {
                        instrument.rate = null;
                        hasMissingExchangeRates = true;
                    }
                }
            } else {
                instrument.rate = null;
            }
        }

        return hasMissingExchangeRates;
    }

    public async fetchSecondmentData() {
        if (!this.participantSecondments || this.participantSecondments.length === 0) {
            if (!this.settings.secondmentConfirmation) {
                const taskRunner = AppService.get(Types.Neo.TaskRunner)
                const participantsApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient)

                const results = await taskRunner.waitForAll({
                    participantSecondments: participantsApiClient.getSecondments(),
                });
                this.participantSecondments.set(results.participantSecondments.data);
            }
        }
        return this.participantSecondments;
    }
}